import React from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";

import Layout from "@components/Layout";
import SEO from "@components/SEO";
import Header from "@components/Header";
import PageHeading from "@components/PageHeading";

const NotFoundPage = ({ location }) => {
  const { t } = useTranslation();

  return (
    <Layout>
      <SEO
        title={t("404.title")}
        description={t("404.description")}
        pathname={location.pathname}
      />
      <Header />
      <main>
        <div className="main-content-wrapper">
          <PageHeading>{t("404.heading")}</PageHeading>
          <p>{t("404.text")}</p>
        </div>
      </main>
    </Layout>
  );
};

NotFoundPage.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string,
  }),
};

export default NotFoundPage;
